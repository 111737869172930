{
  "name": "text-terminal",
  "version": "0.2.0",
  "description": "A simple, extendable terminal interface for the web.",
  "repository": "https://github.com/desholmes/text-terminal",
  "scripts": {
    "clean": "rm -rf ./dist/*.css ./dist/*.map ./dist/*.js ",
    "start": "npm run clean && parcel --no-cache --lazy ./src/index.html",
    "build": "npm run clean && parcel build ./src/scss/text-terminal.scss && parcel build ./src/modules/textTerminal.js",
    "lint": "prettier --check .",
    "lint-fix": "prettier --write ."
  },
  "types": "dist/textTerminal.d.ts",
  "author": "Des Holmes https://github.com/desholmes",
  "license": "MIT",
  "devDependencies": {
    "@parcel/transformer-sass": "2.0.0-rc.0",
    "@parcel/transformer-json": "2.0.0-rc.0",
    "prettier": "2.1.2",
    "parcel": "2.0.0-rc.0",
    "sass": "1.42.1"
  }
}
